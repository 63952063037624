export default class Tooltip {
  constructor(element) {
    this.element = element;
    this.containerMobile = this.element.querySelector('.tooltip_mobile');
    this.tooltips = this.element.querySelectorAll('.tooltip');

    this.widthContainer = this.element.clientWidth;

    this.init();
    this.updatePosition();
  }

  init() {
    window.addEventListener('resize', () => {
      this.updatePosition();
    });

    this.setParams();
  }

  setParams() {
    for (let i = 0; i < this.tooltips.length; i++) {
      const tooltip = this.tooltips[i];

      this.icon = tooltip.querySelector('.tooltip_icon');
      this.content = tooltip.querySelector('.tooltip_content');
      this.icon.addEventListener('click', this.setActive.bind(this));

      this.tooltipMobile = this.containerMobile.querySelector(
        `[data-tooltip= ${tooltip.firstElementChild.dataset.tooltip}]`
      );

      if ('left' in tooltip.dataset) {
        tooltip.style.left = `${tooltip.dataset.left}%`;

        if (tooltip.dataset.left == 'center') {
          tooltip.style.left = '50%';
        } else if (tooltip.dataset.left == 'left') {
          tooltip.style.left = '0';
        } else if (tooltip.dataset.left == 'right') {
          tooltip.style.left = '100%';
        }
      }

      if ('top' in tooltip.dataset) {
        tooltip.style.top = `${tooltip.dataset.top}%`;

        if (tooltip.dataset.center == 'center') {
          tooltip.style.top = '50%';
        } else if (tooltip.dataset.center == 'top') {
          tooltip.style.top = '0';
        } else if (tooltip.dataset.center == 'bottom') {
          tooltip.style.top = '100%';
        }
      }

      if ('background' in tooltip.dataset) {
        this.tooltipMobile.style.background = tooltip.dataset.background;
        this.icon.style.background = tooltip.dataset.background;
        this.content.style.background = tooltip.dataset.background;
      }
      if ('color' in tooltip.dataset) {
        this.tooltipMobile.style.color = tooltip.dataset.color;
        this.icon.style.color = tooltip.dataset.color;
        this.content.style.color = tooltip.dataset.color;
      }
    }
  }

  setActive(event) {
    const currentActives = this.element.querySelectorAll('.is-active');
    this.tooltipID = event.currentTarget.dataset.tooltip;
    this.tooltipContentID = this.containerMobile.querySelector(
      `[data-tooltip= ${this.tooltipID}]`
    );

    event.currentTarget.parentElement.classList.toggle('is-active');
    this.tooltipContentID.classList.toggle('is-active');

    for (let i = 0; i < currentActives.length; i++) {
      const currentActive = currentActives[i];

      currentActive.classList.remove('is-active');
    }
  }

  updatePosition() {
    // console.log(this.element.parentElement.clientWidth);
    // console.log(this.widthContainer);

    let scaleValue =
      this.element.parentElement.clientWidth / this.widthContainer;

    if (this.element.parentElement.clientWidth > this.widthContainer) {
      scaleValue = 1;
    }

    this.element.style.transform = `scale(${scaleValue})`;

    for (let i = 0; i < this.tooltips.length; i++) {
      const tooltip = this.tooltips[i];

      this.icon = tooltip.querySelector('.tooltip_icon');
      this.content = tooltip.querySelector('.tooltip_content');

      const containerRect = tooltip.getBoundingClientRect();
      const iconRect = this.icon.getBoundingClientRect();

      const iconCenterX =
        iconRect.left - containerRect.left + iconRect.width / 2;
      const iconCenterY =
        iconRect.top - containerRect.top + iconRect.height / 2;

      const tooltipLeft = (iconCenterX / containerRect.width) * 100;
      const tooltipTop = (iconCenterY / containerRect.height) * 100;

      // this.content.style.left = `${tooltipLeft}%`;
      // this.content.style.top = `${tooltipTop}%`;
    }
  }
}
