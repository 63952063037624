import Accordion from './components/Accordion';
import Carousel from './components/Carousel';
import ChangeImg from './components/ChangeImg';
import Cursor from './components/Cursor';
import Header from './components/Header';
// import Instagram from './components/Instagram';
import Lightbox from './components/Lightbox';
// import Map from './components/Map';
import Modale from './components/Modale';
import Scrolly from './components/Scrolly';
import Tabs from './components/Tabs';
import Tooltip from './components/Tooltip';
import YouTube from './components/YouTube';

export default class ComponentFactory {
  constructor() {
    this.componentList = {
      Accordion,
      Carousel,
      Cursor,
      Header,
      // Instagram,
      Lightbox,
      // Map,
      Modale,
      Scrolly,
      Tabs,
      Tooltip,
      Tooltip,
      YouTube,
      ChangeImg,
    };
    this.init();
  }

  init() {
    const components = document.querySelectorAll('[data-component]');

    for (let i = 0; i < components.length; i++) {
      const component = components[i];
      const componentName = component.dataset.component;

      if (componentName.includes(',')) {
        const componentNames = componentName.split(',');
        for (let i = 0; i < componentNames.length; i++) {
          const name = componentNames[i];
          console.log(name);

          if (this.componentList[name]) {
            new this.componentList[name](component);
          } else {
            console.log(`La composante ${name} n'existe pas`);
          }
        }
      } else {
        if (this.componentList[componentName]) {
          new this.componentList[componentName](component);
        } else {
          console.log(`La composante ${componentName} n'existe pas`);
        }
      }
    }
  }
}
