export default class ChangeImg {
  constructor(element) {
    this.element = element;
    this.main = this.element.querySelector('.main').querySelector('img');
    this.init();
  }

  init() {
    const thumbsSlides = this.element.querySelectorAll(
      '.swiper-thumbs .swiper-slide'
    );

    thumbsSlides.forEach((slide) => {
      slide.addEventListener('click', this.changeMainImg.bind(this));
    });
  }

  changeMainImg(event) {
    const newSrc = event.currentTarget.querySelector('img').getAttribute('src');
    const oldSrc = this.main.getAttribute('src');

    this.main.setAttribute('src', newSrc);
    event.currentTarget.querySelector('img').setAttribute('src', oldSrc);
    console.log(newSrc);
    console.log(oldSrc);
  }
}
