export default class Header {
  constructor(element) {
    this.element = element;
    this.scrollPosition = 0;
    this.scrollLimit = 0.1;
    this.lastScrollPosition = 0;
    this.html = document.documentElement;
    this.init();
    this.initNavMobile();
  }

  init() {
    window.addEventListener('scroll', this.onScroll.bind(this));

    if ('invert' in this.element.dataset) {
      this.html.classList.add('is-inverted');
      this.html.classList.add('header-is-hidden');
      this.html.classList.add('is-scrolling-down');
    }
  }

  initNavMobile() {
    const toggle = this.element.querySelector('.js-toggle');

    toggle.addEventListener('click', this.onToggleNav.bind(this));
  }

  onToggleNav() {
    this.html.classList.toggle('nav-is-active');
  }

  onScroll(event) {
    this.lastScrollPosition = this.scrollPosition;
    this.scrollPosition = document.scrollingElement.scrollTop;

    this.setHeaderState();
    this.setDirectionState();
  }

  setHeaderState() {
    const scrollHeight = document.scrollingElement.scrollHeight;

    if ('scrollLimit' in this.element.dataset) {
      this.scrollLimit = this.element.dataset.scrollLimit;
    }

    if (this.scrollPosition > scrollHeight * this.scrollLimit) {
      if (!this.element.hasAttribute('data-no-hiding')) {
        this.html.classList.add('header-is-hidden');
      }
    } else {
      this.html.classList.remove('header-is-hidden');
    }

    if ('invert' in this.element.dataset) {
      if (this.scrollPosition < scrollHeight * this.scrollLimit) {
        if (!this.element.hasAttribute('data-no-hiding')) {
          this.html.classList.add('header-is-hidden');
        }
      } else {
        this.html.classList.remove('header-is-hidden');
      }
    }
  }

  setDirectionState() {
    if ('invert' in this.element.dataset) {
      if (this.scrollPosition >= this.lastScrollPosition) {
        this.html.classList.add('is-scrolling-down');
        this.html.classList.remove('is-scrolling-up');
      } else {
        this.html.classList.remove('is-scrolling-down');
        this.html.classList.add('is-scrolling-up');
      }
    } else {
      if (this.scrollPosition >= this.lastScrollPosition) {
        this.html.classList.add('is-scrolling-down');
        this.html.classList.remove('is-scrolling-up');
      } else {
        this.html.classList.remove('is-scrolling-down');
        this.html.classList.add('is-scrolling-up');
      }
    }
  }
}
