import lightbox from 'lightbox2';

export default class Lightbox {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    lightbox.option({
      disableScrolling: true,
      resizeDuration: 200,
      wrapAround: true,
      albumLabel: 'Image %1 sur %2',
      fitImagesInViewport: true,
      positionFromTop: 10,
    });

    const links = this.element.querySelectorAll('a');
    this.galleryName = this.element.dataset.galleryName;

    if (!this.galleryName) {
      this.galleryName = this.generateRandomString(7);
    }
    links.forEach((link) => {
      link.setAttribute('data-lightbox', this.galleryName);
      // console.log(lightbox.options.positionFromTop);
    });
  }

  generateRandomString(length) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;
  }
}
