export default class Tabs {
  constructor(element) {
    this.element = element;
    this.url = new URL(window.location.href);
    this.init();
  }

  init() {
    this.initBtns();
    this.setFirst();
  }

  initBtns() {
    const btns = this.element.querySelectorAll('.js-tab');
    const tabs = this.element.querySelectorAll('[data-tab-content-id]');
    this.setActive({ target: btns[0] });

    for (let i = 0; i < btns.length; i++) {
      const btn = btns[i];
      btn.addEventListener('click', this.setActive.bind(this));
    }
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];
      tab.classList.add('inactive');
    }
  }

  setFirst() {
    const params = new URLSearchParams(this.url.search);
    const id = params.get('id');

    const btnsActifs = this.element.querySelectorAll('.is-active');

    for (let i = 0; i < btnsActifs.length; i++) {
      const btnActif = btnsActifs[i];
      btnActif.classList.remove('is-active');
    }

    if (id) {
      this.active = this.element.querySelector(`[data-tab-content-id= ${id}]`);
      this.activeBtn = this.element.querySelector(`[data-tab-id= ${id}]`);
      this.active.classList.add('is-active');
      this.activeBtn.classList.add('is-active');
    } else {
      const btns = this.element.querySelectorAll('.js-tab');
      this.setActive({ target: btns[0] });
    }
  }

  setActive(event) {
    this.tabID = event.target.dataset.tabId;
    const btnsActifs = this.element.querySelectorAll('.is-active');

    for (let i = 0; i < btnsActifs.length; i++) {
      const btnActif = btnsActifs[i];
      btnActif.classList.remove('is-active');
    }
    event.target.classList.add('is-active');

    this.tabContentID = this.element.querySelector(
      `[data-tab-content-id= ${this.tabID}]`
    );
    this.tabContentID.classList.add('is-active');
  }

  // setActive(event) {
  //   this.tabID = event.target.dataset.tabId;
  //   const btnsActifs = this.element.querySelectorAll('.is-active');

  //   for (let i = 0; i < btnsActifs.length; i++) {
  //     const btnActif = btnsActifs[i];
  //     btnActif.classList.remove('is-active');
  //   }
  //   event.target.classList.add('is-active');

  //   this.tabContentID = this.element.querySelector(
  //     `[data-tab-content-id= ${this.tabID}]`
  //   );
  //   this.tabContentID.classList.add('is-active');
  // }
}
